
import { useState, useEffect } from 'react';
import AllForms from './Components/AllForms';
import { closeIcon } from './utils/icons';

const realTime = {
	day: 1000 * 60 * 60 * 24,
	hour: 1000 * 60 * 60,
	minute: 1000 * 60
}

const Forms = ({ attributes, children }) => {

	const { elements, content, placeholder, theme, message, config, layout, popupBtn } = attributes;
	const { placement, popupTimeOut, cacheTimeType = 'day', cacheTime } = layout;
	const { audienceId, endpoint_url } = config;

	const [email, setEmail] = useState("");
	const [fName, setFName] = useState("");
	const [lName, setLName] = useState("");
	const [loading, setLoading] = useState();
	const [showNotice, setShowNotice] = useState();
	const [localMessage, setLocalMessage] = useState();
	const [emailValid, setEmailValid] = useState();
	const [classAddDisable, setClassAddDisable] = useState(true);

	const [isOpen, setIsOpen] = useState(false);

	// Check the time since the modal was last closed
	useEffect(() => {
		const lastClosedTime = localStorage.getItem("modalClosedTime");

		const currentTime = Date.now();
		const cacheDuration = cacheTime * realTime[cacheTimeType];

		if (lastClosedTime && (currentTime - lastClosedTime) < cacheDuration) {
			// The cached time hasn't expired yet; do not show the modal
			return;
		}

		// Open the modal based on placement settings
		if (placement === "auto-popup") {
			const timer = setTimeout(() => {
				setIsOpen(true);
			}, popupTimeOut * 1000);

			return () => clearTimeout(timer);
		}

		if (placement === "floating-bottom" || placement === "floating-top") {
			setIsOpen(true);
		}
	}, []);


	// Set the modal closed time when closing the modal
	const closeModal = () => {
		if (placement !== 'modal') {
			localStorage.setItem("modalClosedTime", Date.now());
		}
		setIsOpen(false);
	};

	// Email Validation
	const ValidateEmail = (input) => {
		const validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
		if (input.match(validRegex)) {
			setEmailValid(true);
			setClassAddDisable(true);
		} else {
			setEmailValid(false);
			setClassAddDisable(false);
		}
	};

	// Fetch the data
	const handleFormData = async () => {
		try {
			setLoading(true);
			const res = await fetch(`${mcbData?.ajaxUrl}?action=mcbSubmit_Form_Data&nonce=${mcbData?.nonce}&audienceId=${audienceId}&email=${email}&fName=${fName}&lName=${lName}&endpoint_url=${endpoint_url}`);
			setLoading(false);
			const response = await res.json();
			statusCheck(response.status);
			setClassAddDisable(true);
			setShowNotice(true);
			setTimeout(() => {
				setShowNotice(false);
			}, 3000);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const globalProps = { child: children, classAddDisable, elements, fName, setFName, lName, setLName, email, setEmail, placeholder, ValidateEmail, content, handleFormData, emailValid, loading, showNotice, message: localMessage };

	const statusCheck = (status) => {
		if (status === "subscribed") {
			setLocalMessage(<p className='success'>{message?.success}</p>);
			setEmail("");
			setFName("");
			setLName("");
			setClassAddDisable(false);
			setEmailValid(true);
		} else if (status === 400) {
			setLocalMessage(<p className='error'>{message?.error}</p>);
		} else if (status === 404) {
			setLocalMessage(<p className='error'>Audience Id Not Found!</p>);
		} else if (status === 502) {
			setLocalMessage(<p className='error'>Invalid API Key!</p>);
		} else if (status === 510) {
			setLocalMessage(<p className='error'>Audience ID Required!</p>);
		} else {
			setLocalMessage(<p className='error'>Invalid API Key!</p>);
		}
	};

	return (
		<div className={`mcbMailChimp ${theme} ${theme === "form9" ? "form4" : ""} ${theme === "form10" ? "form6" : ""} ${placement === "floating-top" ? "form7" : ""}`}>
			{placement === "modal" ? (
				<div className='popupBtnArea'>
					<button className='popupBtn' onClick={() => setIsOpen(true)}>
						{popupBtn?.text}
					</button>
				</div>
			) : (
				placement === "floating-top" || placement === "floating-bottom" || placement === "auto-popup" ? null : (
					<AllForms theme={theme} globalProps={globalProps} attributes={attributes} />
				)
			)}
			{isOpen && (
				<Modal setIsOpen={closeModal} attributes={attributes}>
					<AllForms theme={theme} globalProps={globalProps} attributes={attributes} />
				</Modal>
			)}
		</div>
	);
};

export default Forms;

export const Modal = ({ children, setIsOpen, attributes }) => {
	const { layout, closeBtn } = attributes;
	const { colors } = closeBtn;

	return (
		<div
			className={`modalArea ${layout?.placement === "floating-bottom" ? 'modalAreaFBottom' : layout?.placement === "floating-top" ? 'modalAreaFTop' : 'modalAreaNatural'}`}
			onClick={(e) => {
				if (e.target.classList.contains('modalArea')) {
					setIsOpen();
				}
			}}
		>
			<div className="allForms">
				<div className="closeIcon" onClick={() => setIsOpen()}>
					{closeIcon(colors?.color)}
				</div>
				{children}
			</div>
		</div>
	);
};
