import Button from '../Button';
import Email from '../Email';
import FirstName from '../FirstName';
import LastName from '../LastName';
import Notice from '../Notice';

const FormFour = ({ child, classAddDisable, elements, fName, lName, email, placeholder, setFName, setLName, setEmail, ValidateEmail, content, handleFormData, emailValid, loading, showNotice, message, attributes }) => {
    const commonProps = { elements, placeholder };
    const { theme } = attributes;

    return <div className="form">
        <div className="left">
            {theme === "form4" && child}

        </div>
        <div className="right">
            {theme === "form9" && child}
            <FirstName {...commonProps} fName={fName} setFName={setFName} placeholder={placeholder} />
            <LastName {...commonProps} lName={lName} setLName={setLName} placeholder={placeholder} />
            <Email email={email} setEmail={setEmail} placeholder={placeholder} ValidateEmail={ValidateEmail} />
            <Button classAddDisable={classAddDisable} content={content} handleFormData={handleFormData} emailValid={emailValid} loading={loading} />

            <Notice showNotice={showNotice} message={message} />
        </div>

    </div>
}
export default FormFour;